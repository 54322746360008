import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

function SubText({ text, children }) {
  return (
    <Layout>
      <MainTitle>{text.mainTitle}</MainTitle>
      <Paragraph>{text.par}</Paragraph>
      <Title>{text.title}</Title>
      {children}
      <Desc>{text.desc}</Desc>
      <Point>
        <Bold>{text.bold}</Bold>
        {text.text}
      </Point>
    </Layout>
  );
}
SubText.propTypes = {
  text: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default SubText;

const Layout = styled.div`
  width: 85%;
  margin: auto;
`;

const Point = styled.p``;

const MainTitle = styled.h1`
  font-size: 3.2rem;
  font-weight: 600;
  margin-bottom: 60px;
  @media screen and (max-width: 750px) {
    font-size: 2.5rem;
  }
`;

const Bold = styled.div`
  font-weight: 700;
`;

const Title = styled(MainTitle)`
  font-size: 1.6rem;
  margin: 20px 0;
  @media screen and (max-width: 750px) {
    font-size: 2rem;
  }
  @media screen and (max-width: 500px) {
    font-size: 1.3rem;
  }
`;
const Paragraph = styled(Title)`
  font-size: 2.5rem;
  @media screen and (max-width: 750px) {
    font-size: 2rem;
  }
  @media screen and (max-width: 500px) {
    font-size: 1.7rem;
  }
`;

const Desc = styled.div`
  font-size: 1.2rem;
`;
