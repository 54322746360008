import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    background-color: transparent;
  }

  body{
    color: ${(props) => (props.whiteMode ? "black" : "white")};
    background: ${(props) =>
      props.whiteMode ? "white" : "linear-gradient(to left, #422784, #6A4FD4)"};
    min-width: 350px;
  }
`;

export default GlobalStyle;
