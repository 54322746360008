import React from "react";
import PropTypes from "prop-types";
import Footer from "../Footer";
import { TextWrapper } from "../Header";
import SprzedamSzkodeText from "../SprzedamSzkodeText/SprzedamSzkodeText";
import styled from "styled-components";

function SubPage({ children }) {
  return (
    <>
      {/* *******  TOP HEADER ******* */}
      <Header>
        <WrapperModified>
          <a href="/">
            <SprzedamSzkodeText />
          </a>
        </WrapperModified>
      </Header>
      {/* *******  MIDDLE SECTION ******* */}
      {children}
      {/* *******  BOTTOM SECTION ******* */}
      <Footer />
    </>
  );
}

SubPage.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SubPage;

const Header = styled.div`
  width: 100%;
  background: linear-gradient(to left, #422784, #6a4fd4);
  height: 120px;
  border-bottom: 2px solid #ffdd00;
  margin-bottom: 80px;
  padding: 20px;
  padding-bottom: 20px;
`;

const WrapperModified = styled(TextWrapper)`
  padding: 0;
`;
