import React from "react";
import GlobalStyle from "../styles/GlobalStyles";
import SubPage from "../components/SubPage/SubPage";
import SubText from "../components/SubPage/SubText";
import polityka from "../data/polityka.json";

function Policy() {
  return (
    <SubPage>
      <GlobalStyle whiteMode />
      {polityka.map((el, index) => (
        <SubText text={el} key={index} />
      ))}
    </SubPage>
  );
}

export default Policy;
